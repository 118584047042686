import moment from "moment";
import React from "react";
import "./Dokumentat.scss";
import DokumentComp from "./DokumentComp/DokumentComp";
import DynamicButton from "components/GlobalComponents/DynamicButton/DynamicButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export default function Dokumentat({ lloji, dokumenta, struktureId }) {
  // console.log(rendi);
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <div className="komisioni-dokumentat-wrapper">
      <strong>
        <a id="doku">
          <h2>{lloji}</h2>
        </a>
      </strong>
      <div className="rendi-list">
        {dokumenta
          .sort((a, b) => {
            return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
          })
          .map((dok) => (
            <DokumentComp key={dok.id} data={dok} />
          ))}
      </div>
      {lloji === "PROCESVERBAL" && dokumenta.length >= 9 && (
        <DynamicButton
          text={t("navigation.more")}
          action={() =>
            history.push(
              `/dokumenta/${struktureId}/cc439e41-586a-45b0-a005-a505f6a141a4`
            )
          }
          type="secondary"
        />
      )}
      {lloji === "KALENDARIPUNIMEVE" && dokumenta.length >= 9 && (
        <DynamicButton
          text={t("navigation.more")}
          action={() =>
            history.push(
              `/dokumenta/${struktureId}/7587bd49-801e-40c9-aba2-5bbcf228a649`
            )
          }
          type="secondary"
        />
      )}
      {lloji === "PROGRAMPUNE" && dokumenta.length >= 9 && (
        <DynamicButton
          text={t("navigation.more")}
          action={() =>
            history.push(
              `/dokumenta/${struktureId}/97756fcb-b0c7-4c60-969a-ab07be7d0ad7`
            )
          }
          type="secondary"
        />
      )}
      {lloji === "Vënde të lira pune" && dokumenta.length >= 9 && (
        <DynamicButton
          text={t("navigation.more")}
          action={() =>
            history.push(
              `/dokumenta/${struktureId}/70fb7b1f-d6b4-4f81-bf7a-d7b54a5c73db`
            )
          }
          type="secondary"
        />
      )}
      {lloji === "VENDIM" && dokumenta.length >= 9 && (
        <DynamicButton
          text={t("navigation.more")}
          action={() =>
            history.push(
              `/dokumenta/${struktureId}/b04fb3ad-a1fa-49dd-8b69-5eb6b88df16b`
            )
          }
          type="secondary"
        />
      )}
      {lloji === "Raporte Paga dhe Përfitime" && dokumenta.length >= 9 && (
        <DynamicButton
          text={t("navigation.more")}
          action={() =>
            history.push(
              `/dokumenta/${struktureId}/fbb4ce63-a256-4cac-ab71-e8b2c9183bca`
            )
          }
          type="secondary"
        />
      )}
      {lloji === "Raporte" && dokumenta.length >= 9 && (
        <DynamicButton
          text={t("navigation.more")}
          action={() =>
            history.push(
              `/dokumenta/${struktureId}/23a3d23c-687e-4dd8-9067-926d3c482a3d`
            )
          }
          type="secondary"
        />
      )}
      {lloji === "Si kanë votuar Deputetët" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/7e1e70cd-8e5a-4f70-88f1-55a51e3e5da7`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "REZOLUTE" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/4a0481ea-cfd8-4c53-8f65-fb8f8514e051`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "LIGJ" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/2cd8d8bf-73ab-402f-b213-874ba7f9563e`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "Dokumenta shoqërues të Seancës Plenare" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/e4c53be7-9980-45b3-9f48-2c5a1bd0adf0`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "RAPORT" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/52b24501-19a7-46a2-8f20-d02db542c5b7`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "Ligji i miratuar" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/f15601f8-c1ee-4742-bfba-aebd62c8b85a`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "Interpelanca" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/82087d8b-dcce-4830-bdcb-c8d88cded624`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "Mocioni" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/5f34ea82-5e80-4d41-bfba-601412bb398f`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "KERKESE" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/52867e86-955f-4996-92f5-758b61bbf193`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "Raporte Monitorimi" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/5fd8986e-e683-4de3-aeef-8c8852c09ac9`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "Publikime" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/de1ccde6-bad9-4ff6-b724-1c49b4ef53c2`
              )
            }
            type="secondary"
          />
        )}
      {lloji ===
        "Funksionarë publik aktual të institucioneve të pavarura kushtetuese dhe atyre të krijuara me ligj" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/e6d0691d-5526-4a89-a398-c9a8442cf1a5`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "Aktiviteti i Kuvendit në seancë plenare" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/46353839-fea2-4845-808d-e2e1d2dc46c1`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "Revista Kuvendi" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/2459e812-7a71-4d7d-a2b6-7a62d5d72e61`
              )
            }
            type="secondary"
          />
        )}
      {lloji ===
        "Fjalime të Kryetarëve të Kuvendit, Kryeministrave dhe personaliteteve ndërkombëtare" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/68e27856-ce97-4342-8f94-63a35a47f7f3`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "Punime te Kuvendit" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/781d9afb-307e-41f5-bd29-93d97c5d5148`
              )
            }
            type="secondary"
          />
        )}
      {lloji === "Statistika" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/84791ab8-c769-4c2f-8dc1-b211a8e88384`
              )
            }
            type="secondary"
          />
        )}
      {lloji ===
        "Deklarime të veçanta të deputetëve në gjendje konflikti interesi" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/bda2bdb5-36a0-47d2-ab9f-fd200af1fdeb`
              )
            }
            type="secondary"
          />
        )}
      {lloji ===
        "Deklarime të deputetëve për pjesëmarrje në veprimtaritë e organizuara nga të tretë" &&
        dokumenta.sort((a, b) => {
          return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
        }).length >= 9 && (
          <DynamicButton
            text={t("navigation.more")}
            action={() =>
              history.push(
                `/dokumenta/${struktureId}/661afc91-3863-4450-b69f-7f0c2ea4ff9e`
              )
            }
            type="secondary"
          />
        )}
    </div>
  );
}
